import router from "@/router";
import i18n from "@/plugins/i18n";
import moment from "moment-timezone";
import _ from "lodash";
import store from "@/store";

import {
  login,
  logout,
  forgotPassword,
  language,
  acceptConditions
} from "@/api/auth.api";
import { getConstants } from "@/api/misc.api";
import { validRFC5322 } from "@/utils/validators";

const state = {
  status: "",
  token: localStorage.getItem("token") || "",
  user: {},
  profiles: _.words(localStorage.getItem("profiles"), /[^, ]+/g) || "",
  adminProfiles: _.words(localStorage.getItem("profiles"), /[^, ]+/g) || "",
  controllerProfiles:
    _.words(localStorage.getItem("profiles"), /[^, ]+/g) || "",
  errorCode: false,
  activeServices: _.words(localStorage.getItem("services"), /[^, ]+/g),
  step: "username",
  lang:
    localStorage.getItem("language") !== "es" &&
    localStorage.getItem("language") !== "en"
      ? localStorage.getItem("language")
      : navigator.language.toLowerCase() || "es-es"
};

const getters = {
  lang: state => state.lang,
  errorCode: state => state.errorCode,
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  profiles: state => state.profiles,
  isController: state => _.indexOf(state.profiles, "CONTROLLER") >= 0,
  isUser: state => _.indexOf(state.profiles, "USER") >= 0,
  step: state => state.step,
  user: state => state.user,
  services: state =>
    state.activeServices ||
    _.words(localStorage.getItem("services"), /[^, ]+/g),
  hasModule: state => {
    return modules => {
      if (modules) {
        return _.intersectionBy(modules, state.activeServices).length > 0;
      } else if (modules === undefined) {
        return true;
      } else {
        return false;
      }
    };
  },
  defaultView: () => {
    var view = localStorage.getItem("view") || false;
    var def = "ControllerDashboard";
    if (view) {
      view = JSON.parse(view);
      if (view.defaultDashboard) {
        def = view.defaultDashboard;
      }
    }
    return def;
  }
};

const actions = {
  loginBySteps({ commit, state }, form) {
    commit("auth_request");
    let loginData = {};
    switch (state.step) {
      case "username":
        if (_.isEmpty(form.login)) {
          commit("auth_empty", "empty");
        } else {
          loginData.login = form.login;
          loginData.locale = localStorage.getItem("language");
          login(loginData)
            .then(resp => {
              let data = resp.data.records;
              if (data.redirect !== false) {
                window.location.replace(data.url);
              } else {
                commit("set_step", "password");
              }
            })
            .catch(error => {
              if (error.status === 401) {
                commit(
                  "auth_blocked",
                  error.response.data.records.applicationCode
                );
                localStorage.removeItem("token");
              } else {
                commit(
                  "auth_error",
                  error.response.data.records.applicationCode
                );
                localStorage.removeItem("token");
              }
            });
        }
        break;
      case "password":
        if (_.isEmpty(form.login) || _.isEmpty(form.password)) {
          commit("auth_empty", "empty");
        } else {
          loginData.login = form.login;
          loginData.password = Buffer.from(form.password).toString("base64");
          if (localStorage.getItem("language")) {
            loginData.locale = localStorage.getItem("language");
          }
          login(loginData)
            .then(resp => {
              getConstants("SERVICETYPE_").then(servicesResp => {
                let services = servicesResp.data.records;
                let data = resp.data.records;

                let languages = _.intersection(
                  Object.keys(i18n.messages),
                  data.languages
                );

                if (_.includes(languages, localStorage.getItem("language"))) {
                  data.locale = localStorage.getItem("language");
                  language("locale=" + data.locale.toLowerCase()).then(() => {
                    commit("change_language", data.locale);
                  });
                } else {
                  localStorage.setItem("language", data.locale);
                  commit("change_language", data.locale);
                }

                if (!data.termsOfUse || !data.privacyPolicy) {
                  localStorage.setItem("token", data.token);
                  commit("auth_conditions");
                  commit("set_step", "conditions");
                } else {
                  let user = {
                    stakeholderId: data.stakeholderUserId,
                    conditions: {
                      termsOfUse: data.termsOfUse,
                      privacyPolicy: data.privacyPolicy
                    },
                    companyId: data.stakeholderCompanyId,
                    lang: data.locale.toLowerCase(),
                    languages: data.languages
                  };
                  localStorage.setItem(
                    "languages",
                    JSON.stringify(data.languages)
                  );
                  commit("change_language", data.locale.toLowerCase());
                  localStorage.setItem(
                    "user_services",
                    JSON.stringify(data.services.USER)
                  );
                  commit("set_services", {
                    services: services,
                    userServices: data.services.USER
                  });
                  localStorage.setItem("token", data.token);
                  localStorage.setItem("stakeholderId", data.stakeholderUserId);
                  localStorage.setItem("companyId", data.stakeholderCompanyId);
                  localStorage.setItem("federated", data.federated);
                  localStorage.setItem(
                    "stakeholderRelationShip",
                    data.stakeholderRelationShip
                  );
                  commit("set_profiles", data.profiles);
                  commit("auth_success", {
                    token: data.token,
                    user: user
                  });
                }
              });
            })
            .catch(error => {
              if (error.status === 401) {
                commit(
                  "auth_blocked",
                  error.response.data.records.applicationCode
                );
                localStorage.removeItem("token");
              } else {
                commit(
                  "auth_error",
                  error.response.data.records.applicationCode
                );
                localStorage.removeItem("token");
              }
            });
        }
        break;
      case "conditions":
        if (
          _.isEmpty(form.login) ||
          _.isEmpty(form.password) ||
          !_.isEqual(form.termsOfUse, true) ||
          !_.isEqual(form.privacyPolicy, true)
        ) {
          commit("auth_empty", "empty");
        } else {
          acceptConditions({
            termsOfUse: form.termsOfUse ? 1 : 0,
            privacyPolicy: form.privacyPolicy ? 1 : 0
          }).then(response => {
            if (response.data.records[0] === "KO") {
              commit("auth_conditions");
              commit("set_step", "conditions");
            } else {
              localStorage.removeItem("token");
              commit("set_step", "password");
              store.dispatch("Auth/loginBySteps", form);
            }
          });
        }
        break;
    }
  },
  login({ commit }, user) {
    commit("auth_request");
    if (user.login === "" || user.password === "") {
      commit("auth_empty", "empty");
    } else if (user.privacyPolicy || user.termsOfUse) {
      acceptConditions(user).then(resp => {
        if (resp.data.records[0] === "KO") {
          commit("auth_conditions");
        } else {
          localStorage.removeItem("token");
          login(user)
            .then(resp => {
              var data = resp.data.records;
              var user = {
                stakeholderId: data.stakeholderUserId,
                conditions: {
                  termsOfUse: data.termsOfUse,
                  privacyPolicy: data.privacyPolicy
                },
                companyId: data.stakeholderCompanyId,
                lang: data.locale.toLowerCase()
              };
              localStorage.setItem("token", data.token);
              localStorage.setItem("stakeholderId", data.stakeholderUserId);
              localStorage.setItem("companyId", data.stakeholderCompanyId);
              localStorage.setItem("federated", data.federated);
              localStorage.setItem(
                "stakeholderRelationShip",
                data.stakeholderRelationShip
              );
              commit("set_profiles", data.profiles);
              commit("auth_success", data.token, user);
            })
            .catch(error => {
              if (error.status === 401) {
                commit(
                  "auth_blocked",
                  error.response.data.records.applicationCode
                );
                localStorage.removeItem("token");
              } else {
                commit(
                  "auth_error",
                  error.response.data.records.applicationCode
                );
                localStorage.removeItem("token");
              }
            });
        }
      });
    } else {
      login(user)
        .then(resp => {
          getConstants("SERVICETYPE_").then(servicesResp => {
            var services = servicesResp.data.records;
            var data = resp.data.records;
            if (!data.termsOfUse || !data.termsOfUse) {
              localStorage.setItem("token", data.token);
              commit("auth_conditions");
            } else {
              var user = {
                stakeholderId: data.stakeholderUserId,
                conditions: {
                  termsOfUse: data.termsOfUse,
                  privacyPolicy: data.privacyPolicy
                },
                companyId: data.stakeholderCompanyId,
                lang: data.locale.toLowerCase()
              };
              commit("change_language", user.lang);
              commit("set_services", {
                services: services,
                userServices: data.services
              });
              localStorage.setItem("token", data.token);
              localStorage.setItem("stakeholderId", data.stakeholderUserId);
              localStorage.setItem("companyId", data.stakeholderCompanyId);
              localStorage.setItem("federated", data.federated);
              localStorage.setItem(
                "stakeholderRelationShip",
                data.stakeholderRelationShip
              );
              commit("set_profiles", data.profiles);
              commit("auth_success", {
                token: data.token,
                user: user
              });
            }
          });
        })
        .catch(error => {
          if (error.status === 401) {
            commit("auth_blocked", error.response.data.records.applicationCode);
            localStorage.removeItem("token");
          } else {
            commit("auth_error", error.response.data.records.applicationCode);
            localStorage.removeItem("token");
          }
        });
    }
  },
  resetSteps({ commit }) {
    commit("set_step", "username");
  },
  logout({ commit, dispatch }) {
    logout()
      .then(() => {
        commit("reset");
        commit("set_step", "username");
        dispatch("Constants/reset", {}, { root: true });
        dispatch("User/reset", {}, { root: true });
        dispatch("Profile/reset", {}, { root: true });
        dispatch("Interactions/reset", {}, { root: true });
        dispatch("Chatbot/reset", {}, { root: true });
        dispatch("Departments/reset", {}, { root: true });
        dispatch("Users/reset", {}, { root: true });
        dispatch("Company/reset", {}, { root: true });
        dispatch("Organization/reset", {}, { root: true });
        dispatch("ActionPlan/reset", {}, { root: true });
        dispatch("Admin/reset", {}, { root: true });
        dispatch("Controller/reset", {}, { root: true });
        dispatch("Controller/resetCompanies", {}, { root: true });
        dispatch("Admin/resetCompanies", {}, { root: true });
        dispatch("Phishing/reset", {}, { root: true });
        dispatch("Translations/reset", {}, { root: true });

        localStorage.removeItem("token");
        localStorage.removeItem("stakeholderId");
        localStorage.removeItem("companyId");
        localStorage.removeItem("managedCompanyId");
        localStorage.removeItem("companySelected");
        localStorage.removeItem("profiles");
        localStorage.removeItem("profile");
        localStorage.removeItem("federated");
        localStorage.removeItem("services");
        localStorage.removeItem("languages");
        localStorage.removeItem("controllerProfiles");
        localStorage.removeItem("adminProfiles");
        localStorage.removeItem("stakeholderRelationShip");
        localStorage.removeItem("user_services");

        if (router.currentRoute.name !== "Login") {
          router.push({ name: "Login" });
        }
      })
      .catch(() => {
        commit("reset");
        commit("set_step", "username");
        dispatch("Constants/reset", {}, { root: true });
        dispatch("User/reset", {}, { root: true });
        dispatch("Profile/reset", {}, { root: true });
        dispatch("Interactions/reset", {}, { root: true });
        dispatch("Chatbot/reset", {}, { root: true });
        dispatch("Departments/reset", {}, { root: true });
        dispatch("Users/reset", {}, { root: true });
        dispatch("Company/reset", {}, { root: true });
        dispatch("Organization/reset", {}, { root: true });
        dispatch("ActionPlan/reset", {}, { root: true });
        dispatch("Admin/reset", {}, { root: true });
        dispatch("Controller/reset", {}, { root: true });
        dispatch("Controller/resetCompanies", {}, { root: true });
        dispatch("Admin/resetCompanies", {}, { root: true });
        dispatch("Phishing/reset", {}, { root: true });
        dispatch("Translations/reset", {}, { root: true });

        localStorage.removeItem("token");
        localStorage.removeItem("stakeholderId");
        localStorage.removeItem("companyId");
        localStorage.removeItem("managedCompanyId");
        localStorage.removeItem("companySelected");
        localStorage.removeItem("profiles");
        localStorage.removeItem("federated");
        localStorage.removeItem("services");
        localStorage.removeItem("languages");
        localStorage.removeItem("controllerProfiles");
        localStorage.removeItem("adminProfiles");
        localStorage.removeItem("stakeholderRelationShip");
        localStorage.removeItem("user_services");
        if (router.currentRoute.name !== "Login") {
          router.push({ name: "Login" });
        }
      });
  },
  remember({ commit }, email) {
    if (email === "") {
      commit("auth_empty");
    } else if (!validRFC5322(email)) {
      commit("auth_invalid_email");
    } else {
      forgotPassword({ login: email })
        .then(() => {
          commit("auth_remember_ok", "remember_sent");
        })
        .catch(error => {
          commit(
            "auth_remember_ok",
            error.response.data.records.applicationCode
          );
        });
    }
  },
  changeLanguage({ commit, state }, lang) {
    if (lang !== state.language) {
      language("locale=" + lang.toLowerCase()).then(() => {
        store.dispatch(`Loader/show`);
        commit("change_language", lang);
        window.location.reload();
      });
    }
  },
  changeLocalLanguage({ commit }, lang) {
    commit("change_language", lang);
  },
  changeDefaultView({ state }, routeName) {
    if (_.indexOf(state.profiles, "CONTROLLER") >= 0) {
      var actualView = {};
      if (localStorage.getItem("view")) {
        actualView = JSON.parse(localStorage.getItem("view"));
      }
      actualView.defaultDashboard = routeName;
      localStorage.setItem("view", JSON.stringify(actualView));
    }
  },
  setToken({ commit }) {
    commit("set_token", localStorage.getItem("token"));
  },
  setServices({ commit }, data) {
    commit("set_services", data);
  },
  setProfiles({ commit }, data) {
    commit("set_profiles", data);
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, data) {
    state.token = data.token;
    state.user = data.user;
    state.status = "success";
  },
  auth_error(state, data) {
    state.status = "error";
    state.errorCode = data;
  },
  auth_blocked(state, data) {
    state.status = "blocked";
    state.errorCode = data;
  },
  auth_empty(state, data) {
    state.status = "empty";
    state.errorCode = data;
  },
  auth_remember_ok(state, data) {
    state.status = "remember_sent";
    state.errorCode = data;
  },
  auth_invalid_email(state) {
    state.status = "invalid_email";
  },
  auth_conditions(state) {
    state.status = "conditions";
  },
  set_token(state, token) {
    state.token = token;
  },
  set_step(state, step) {
    state.step = step;
  },
  change_language(state, lang) {
    localStorage.setItem("language", lang.toLowerCase());
    i18n.locale = lang.toLowerCase();
    state.lang = lang.toLowerCase();
    let momentLang = lang.toLowerCase();
    if (lang.toLowerCase() == "cat") {
      momentLang = "ca";
    }
    moment.locale(momentLang);
  },
  reset(state) {
    state.status = "";
    state.token = "";
    state.user = {};
    state.activeServices = [];
    state.step = "username";
  },
  resetPassword(state) {
    state.status = "";
    state.token = "";
    state.user = {};
    state.activeServices = [];
    state.step = "password";
  },
  set_profiles(state, profiles) {
    state.profiles = _.words(_.startCase(_.keys(profiles)));
    localStorage.setItem("profiles", state.profiles);
    if (profiles.ADMIN) {
      localStorage.setItem("adminProfiles", _.toString(profiles.ADMIN));
    }
    if (profiles.CONTROLLER) {
      localStorage.setItem(
        "controllerProfiles",
        _.toString(profiles.CONTROLLER)
      );
    }
  },
  set_services(state, data) {
    let services = data.services;
    let userServices = data.userServices;
    state.activeServices = [];
    _.each(userServices, service => {
      let index = _.indexOf(
        _.values(_.mapValues(services, service => parseInt(service))),
        parseInt(service)
      );
      if (index >= 0) {
        let words = _.words(_.startCase(_.keys(services)[index]));
        let service = words.splice(1, words.length - 1).join("_");
        state.activeServices.push(_.toLower(service));
      }
    });

    localStorage.setItem("services", state.activeServices);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
