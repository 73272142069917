import axios from "axios";
import _ from "lodash";
import i18n from "@/plugins/i18n";

import {
  getProfile,
  getInteractions,
  getStakeholderActions,
  getStakeholdersActions,
  getActionsTexts
} from "@/api/controller/traits.api";
import {
  getUsers,
  getAssets,
  generateActionPlan
} from "@/api/controller/stakeholders.api";

const state = {
  interactions: [],
  users: [],
  actions: [],
  usersByRisk: {},
  actionsByRisk: {},
  awarenessActions: {},
  followActions: {},
  wellbeingActions: {},
  reports: [],
  riskStatus: "",
  restStatus: ""
};

const getters = {
  isRiskLoaded: state => state.riskStatus === "success",
  isRestLoaded: state => state.restStatus === "success",
  usersByRisk: state => state.usersByRisk,
  actionsByRisk: state => state.actionsByRisk,
  usersByAwareness: state => {
    var result = {};
    _.forEach(state.awarenessActions, element => {
      result[element.executeAction.title] = element.count;
    });
    return result;
  },
  actionsByAwareness: state => _.toArray(state.awarenessActions),
  usersByWellbeing: state => {
    var result = {};
    _.forEach(state.wellbeingActions, element => {
      result[element.executeAction.title] = element.count;
    });
    return result;
  },
  actionsByWellbeing: state => state.wellbeingActions,
  usersByFollow: state => {
    var result = {};
    _.forEach(state.followActions, element => {
      result[element.executeAction.title] = element.count;
    });
    return result;
  },
  actionsByFollow: state => state.followActions,
  reports: state => state.reports
};

const actions = {
  loadGeneralPlan({ commit, rootGetters }) {
    const companyId = localStorage.getItem("managedCompanyId");
    var profileParams = {
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_RISK")
      ],
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL")
      ],
      dimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_GENERAL_RISK")
      ]
    };
    var actionParams = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL")
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_ACTION_PLAN")
      ],
      actionTypes: [
        rootGetters["Constants/getConstant"]("ACTIONS_TYPE_ACTION_PLAN")
      ]
    };
    const actionPlanAssetsParams = {
      family: "ActionPlan"
    };
    axios
      .all([
        getUsers("companies", companyId),
        getInteractions(companyId),
        getStakeholderActions(companyId, actionParams),
        getAssets(companyId, actionPlanAssetsParams)
      ])
      .then(
        axios.spread((usersResp, interactionsResp, actionsResp, assetsResp) => {
          commit("set_users", usersResp.data.records);
          commit("set_interactions", interactionsResp.data.records);
          commit("set_risk_actions", actionsResp.data.records);
          commit("set_reports", assetsResp.data.records);
          var usersIds = _.map(
            usersResp.data.records,
            user => user.stakeholderId
          );
          var pager = 0;
          var limit = 100;
          var requests = [];
          while (pager * limit < _.size(usersIds)) {
            requests.push(
              getProfile(
                companyId,
                _.merge(
                  {
                    stakeholdersId: _.slice(
                      usersIds,
                      pager * limit,
                      (pager + 1) * limit
                    )
                  },
                  profileParams
                )
              )
            );
            pager++;
          }
          axios.all(requests).then(
            axios.spread((...responses) => {
              var profiles = false;
              _.each(responses, response => {
                profiles = _.merge(profiles, response.data.records);
              });
              commit("set_risks", {
                profiles: profiles,
                interactions: interactionsResp.data.records,
                rootGetters: rootGetters
              });
              commit("set_risk_done");
            })
          );
        })
      );
  },
  loadRestPlan({ commit, rootGetters }) {
    const companyId = localStorage.getItem("managedCompanyId");
    var actionUsersParams = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL")
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_ACTION_PLAN")
      ],
      actionTypes: [
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ACTION_PLAN_AWARENESS"
        ),
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ACTION_PLAN_TRACING"
        ),
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ACTION_PLAN_WELLBEING"
        )
      ]
    };

    getStakeholdersActions(
      "companies",
      "people",
      companyId,
      actionUsersParams
    ).then(actionsResp => {
      state.restActions = actionsResp.data.records;
      var actionsIds = _.keys(
        _.groupBy(actionsResp.data.records, element => element.actionId)
      );
      getActionsTexts({ actionsId: actionsIds }).then(textsResp => {
        commit("set_actions", textsResp.data.records);
        var aggregated = _.groupBy(
          actionsResp.data.records,
          element => element.actionTypeId
        );
        commit(
          "set_awareness_actions",
          aggregated[
            rootGetters["Constants/getConstant"](
              "ACTIONS_TYPE_ACTION_PLAN_AWARENESS"
            )
          ]
        );
        commit(
          "set_follow_actions",
          aggregated[
            rootGetters["Constants/getConstant"](
              "ACTIONS_TYPE_ACTION_PLAN_TRACING"
            )
          ]
        );
        commit(
          "set_wellbeing_actions",
          aggregated[
            rootGetters["Constants/getConstant"](
              "ACTIONS_TYPE_ACTION_PLAN_WELLBEING"
            )
          ]
        );
        commit("set_rest_done");
      });
    });
  },
  generateReport() {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    generateActionPlan(stakeholderCompanyId);
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  set_risks(state, data) {
    state.interactions = data.interactions;
    delete data.profiles[localStorage.getItem("managedCompanyId")];
    var lastInteraction = _.findLast(data.interactions, interaction => {
      return (
        parseInt(interaction.surveyTypeId) ===
        parseInt(
          data.rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL")
        )
      );
    });
    var riskGroup = data.rootGetters["Constants/getConstant"](
      "DIMENSION_GROUP_RISK"
    );
    var riskElement = data.rootGetters["Constants/getConstant"](
      "DIMENSION_ELEMENT_GENERAL_RISK"
    );
    var agrupation = _.countBy(_.toArray(data.profiles), riskDimension => {
      if (
        riskDimension[riskGroup] &&
        riskDimension[riskGroup][riskElement] &&
        riskDimension[riskGroup][riskElement][lastInteraction.surveyEntityId]
      ) {
        {
          return _.last(
            _.orderBy(
              riskDimension[riskGroup][riskElement][
                lastInteraction.surveyEntityId
              ]
            ),
            "startDate",
            "ASC"
          ).dimensionValue;
        }
      }
    });
    var result = {};
    for (let i = 1; i < 7; i++) {
      if (!agrupation[i]) {
        result[i18n.t("generic.modules.risk.level") + " " + i] = 0;
      } else {
        result[i18n.t("generic.modules.risk.level") + " " + i] = agrupation[i];
      }
    }
    state.usersByRisk = result;
  },
  set_risk_actions(state, data) {
    state.actionsByRisk = data;
  },
  set_risk_done(state) {
    state.riskStatus = "success";
  },
  set_actions(state, data) {
    state.actions = data;
  },
  set_awareness_actions(state, data) {
    var toSave = {};
    var byDimension = _.groupBy(data, el => el.dimensionId);
    var keys = _.keys(byDimension);
    _.each(_.toArray(byDimension), (element, key) => {
      if (!toSave[key]) {
        var action = _.find(state.actions, action => {
          return parseInt(action.actionId) === parseInt(element[0].actionId);
        });
        toSave[key] = {
          executeAction: action
            ? action.executeAction
            : { title: "", description: "" }
        };
      }
      toSave[key].count = element.length;
      toSave[key].stakeholderIds = _.map(element, el => el.stakeholderId);
      toSave[key].dimensionId = keys[key];
    });
    state.awarenessActions = _.orderBy(_.toArray(toSave), [el => el.count]);
  },
  set_follow_actions(state, data) {
    var toSave = {};
    var byDimension = _.groupBy(data, el => el.dimensionId);
    var keys = _.keys(byDimension);
    _.each(_.toArray(byDimension), (element, key) => {
      if (!toSave[key]) {
        var action = _.find(state.actions, action => {
          return parseInt(action.actionId) === parseInt(element[0].actionId);
        });
        toSave[key] = {
          executeAction: action
            ? action.executeAction
            : { title: "", description: "" }
        };
      }
      toSave[key].count = element.length;
      toSave[key].stakeholderIds = _.map(element, el => el.stakeholderId);
      toSave[key].dimensionId = keys[key];
    });
    state.followActions = _.orderBy(_.toArray(toSave), [el => el.count]);
  },
  set_wellbeing_actions(state, data) {
    var toSave = {};
    var byDimension = _.groupBy(data, el => el.dimensionId);
    var keys = _.keys(byDimension);
    _.each(_.toArray(byDimension), (element, key) => {
      if (!toSave[key]) {
        var action = _.find(state.actions, action => {
          return parseInt(action.actionId) === parseInt(element[0].actionId);
        });
        toSave[key] = {
          executeAction: action
            ? action.executeAction
            : { title: "", description: "" }
        };
      }
      toSave[key].count = element.length;
      toSave[key].stakeholderIds = _.map(element, el => el.stakeholderId);
      toSave[key].dimensionId = keys[key];
    });
    state.wellbeingActions = _.orderBy(_.toArray(toSave), [el => el.count]);
  },
  set_users(state, data) {
    state.users = data;
  },
  set_interactions(state, data) {
    state.interactions = data;
  },
  set_reports(state, data) {
    state.reports = data;
  },
  set_error(state) {
    state.status = "error";
  },
  set_rest_done(state) {
    state.restStatus = "success";
  },
  reset(state) {
    state.interactions = [];
    state.users = [];
    state.actions = [];
    state.usersByRisk = {};
    state.actionsByRisk = {};
    state.awarenessActions = {};
    state.followActions = {};
    state.wellbeingActions = {};
    state.reports = [];
    state.riskStatus = "";
    state.restStatus = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
