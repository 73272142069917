/* eslint-disable no-console */

import { register } from "register-service-worker";
import Swal from "sweetalert2";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 30);
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");
      Swal.fire({
        title: "Warning!",
        text: "New content is available; please refresh",
        icon: "warning",
        confirmButtonText: "Cool"
      }).then(() => {
        location.reload();
      });
      registration.update();
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
