"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: `${process.env.VUE_APP_API_SERVER}/${process.env.VUE_APP_API_VERSION}`,
  withCredentials: true // Check cross-site Access-Control
};

const actionScope = `Loader`;
let requestsPending = 0;

const req = {
  pending: () => {
    requestsPending++;
    store.dispatch(`${actionScope}/show`);
  },
  done: () => {
    requestsPending--;
    if (requestsPending <= 0) {
      store.dispatch(`${actionScope}/hide`);
    }
  }
};

const _axios = axios.create(config);

const getAuthToken = () => localStorage.getItem("token");
const authInterceptor = config => {
  if (getAuthToken()) {
    config.headers.Authorization = `Bearer ${getAuthToken()}`;
  }
  if (!config.chat) {
    req.pending();
  }
  return config;
};

_axios.interceptors.request.use(authInterceptor);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    if (!response.config?.chat) {
      req.done();
    }
    switch (response.status) {
      case 200:
      case 403: // Forbiden action
      case 412: // Precondition Failed
        return response;
      case 401: // Unauthorized
        if (store.getters["Auth/isLoggedIn"]) {
          store.dispatch("Auth/logout");
        }
        return Promise.reject(response);
      case 418: // Maintenance
        router.push({ name: "Maintenance" });
        return Promise.reject(response);
      case 404: // Not found
      case 500: // Server error
        router.push({ name: "NotFound" });
        return Promise.reject(response);
      default:
        return Promise.reject(response);
    }
  },
  function(error) {
    req.done();
    if (error.response) {
      switch (error.response.status) {
        case 401: // Unauthorized
          if (store.getters["Auth/isLoggedIn"]) {
            store.dispatch("Auth/logout");
          }
          return Promise.reject(error);
        case 418:
          router.push({ name: "Maintenance" });
          return Promise.reject(error);
        case 404: // Not found
        case 500: // Server error
          router.push({ name: "NotFound" });
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    } else if (store.getters["Auth/isLoggedIn"]) {
      store.dispatch("Auth/logout");
    }
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
