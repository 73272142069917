import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import modules from "./modules";

Vue.use(Vuex);

const debug = process.env.VUE_APP_ENVIRONMENT == "local";

export default new Vuex.Store({
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
