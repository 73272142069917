import Vue from "vue";

const END_POINT = "/user/stakeholders";

const getUserStakeholder = (params = {}) =>
  Vue.axios.get(`${END_POINT}/`, {
    params: params
  });
const updateUserStakeholder = (payload = {}) =>
  Vue.axios.put(`${END_POINT}/`, payload);
const getUserAssets = (params = {}) =>
  Vue.axios.get(`${END_POINT}/assets`, { params: params });

export { getUserStakeholder, updateUserStakeholder, getUserAssets };
