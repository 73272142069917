import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/axios";
import "./plugins/bootstrap-vue";
import "./plugins/apexcharts";
import "./plugins/sweetalert2";
import "./plugins/chat-scroll";
import "./plugins/sentry";
import "./plugins/matomo";

import App from "./App.vue";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";

Vue.config.productionTip = false;
Vue.prototype.$baseUrl = `${process.env.VUE_APP_API_SERVER}/${process.env.VUE_APP_API_VERSION}`;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
