import Vue from "vue";

const END_POINT = "/controller/stakeholders";

const getStakeholder = (
  stakeholderType,
  stakeholderId,
  params = {},
  showLoading = true
) =>
  Vue.axios.get(`${END_POINT}/${stakeholderType}/${stakeholderId}`, {
    params: params,
    chat: !showLoading
  });
const updateStakeholder = (stakeholderType, stakeholderId, payload) =>
  Vue.axios.put(`${END_POINT}/${stakeholderType}/${stakeholderId}`, payload);
const getDepartments = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/companies/${stakeholderId}/departments`, {
    params: params
  });
const getUsers = (stakeholderType, stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/${stakeholderType}/${stakeholderId}/people`, {
    params: params
  });
const getCompanies = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/companies/${stakeholderId}/companies`, {
    params: params
  });
const generateActionPlan = stakeholderCompanyId =>
  Vue.axios.post(`${END_POINT}/assets/actionplan/${stakeholderCompanyId}`);
const getAssets = (stakeholderId, params) =>
  Vue.axios.get(`${END_POINT}/assets/${stakeholderId}`, { params: params });
const getTags = (stakeholderCompanyId, params) =>
  Vue.axios.get(`${END_POINT}/tags/${stakeholderCompanyId}`, {
    params: params
  });
const getUserControllerCompanies = (stakeholderId, params = {}) =>
  Vue.axios.get(`${END_POINT}/people/${stakeholderId}/companies`, {
    params: params
  });

export {
  getStakeholder,
  updateStakeholder,
  getDepartments,
  getUsers,
  getCompanies,
  generateActionPlan,
  getAssets,
  getTags,
  getUserControllerCompanies
};
