import _ from "lodash";
import moment from "moment-timezone";
import store from "@/store";
import axios from "axios";

import { getConstants } from "@/api/misc.api";
import { getUserInteractions } from "@/api/user/traits.api";

const state = {
  types: [],
  interactions: [],
  rawInteractions: [],
  status: ""
};

const getters = {
  getByType: state => {
    return typeId => {
      return _.orderBy(
        _.filter(
          state.interactions,
          e => parseInt(e.typeId) === parseInt(typeId)
        ),
        i => {
          return i.surveyEntityId;
        },
        "desc"
      );
    };
  },
  getBySurveyType: state => {
    return surveyTypesIds => {
      return _.orderBy(
        _.filter(
          state.interactions,
          e => _.indexOf(surveyTypesIds, parseInt(e.surveyTypeId)) !== -1
        ),
        [
          e => {
            let val = 100;
            switch (e.surveyStatus) {
              case "AVAILABLE":
              case "UNAVAILABLE":
                val = 0;
                break;
              case "PROGRESS":
                val = 50;
                break;
            }
            return val;
          },
          e => {
            return parseInt(e.date);
          }
        ],
        ["asc", "desc"]
      );
    };
  },
  getByTypes: state => {
    return types => {
      return _.orderBy(
        _.filter(
          state.interactions,
          e => _.indexOf(types, parseInt(e.typeId)) !== -1
        ),
        [
          e => {
            let val = 100;
            switch (e.surveyStatus) {
              case "AVAILABLE":
              case "UNAVAILABLE":
                val = 0;
                break;
              case "PROGRESS":
                val = 50;
                break;
            }
            return val;
          },
          e => {
            return parseInt(e.date);
          }
        ],
        ["asc", "desc"]
      );
    };
  },
  getById: state => {
    return id => {
      return _.findLast(
        state.interactions,
        e => parseInt(e.id) === parseInt(id)
      );
    };
  },
  getBySurveyEntityId: state => {
    return id => {
      return _.find(
        state.interactions,
        e => parseInt(e.surveyEntityId) === parseInt(id)
      );
    };
  },
  interactions: state => {
    return _.reverse(
      _.orderBy(
        state.interactions,
        [
          e => {
            let val = 100;
            switch (e.surveyStatus) {
              case "AVAILABLE":
              case "UNAVAILABLE":
                val = 0;
                break;
              case "PROGRESS":
                val = 50;
                break;
            }
            return val;
          },
          e => {
            return moment(e.startDate).format("x");
          }
        ],
        ["asc", "desc"]
      )
    );
  },
  sessions: state => {
    return _.orderBy(
      _.filter(
        state.interactions,
        session =>
          parseInt(session.typeId) !==
            parseInt(
              store.getters["Constants/getConstant"](
                "SURVEYTYPE_KYMATIO_PHISHING"
              )
            ) &&
          parseInt(session.typeId) !==
            parseInt(
              store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_ABS")
            )
      ),
      [
        e => {
          let val = 100;
          switch (e.surveyStatus) {
            case "AVAILABLE":
            case "UNAVAILABLE":
              val = 0;
              break;
            case "PROGRESS":
              val = 50;
              break;
          }
          return val;
        },
        e => {
          return moment(e.startDate).format("x");
        }
      ],
      ["asc", "desc"]
    );
  },
  rawInteractions: state => state.rawInteractions,
  types: state => state.types,
  isInteractionsLoaded: state => state.status === "success"
};

const actions = {
  loadInteractions({ commit }) {
    axios
      .all([
        getUserInteractions(false),
        getConstants("SURVEY_KYMATIO_PHISHING")
      ])
      .then(
        axios.spread((resp, constResp) => {
          var interactions = _.filter(_.values(resp.data.records), session => {
            return !_.includes(
              constResp.data.records,
              parseInt(session.surveyTypeId)
            );
          });
          commit("set_interactions", interactions);
          commit("set_done");
        })
      );
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  set_interactions(state, data) {
    state.interactions = [];
    state.rawInteractions = data;
    _.forEach(data, e => {
      var survey = {
        id: e.surveyId,
        title: e.surveyName,
        typeId: parseInt(e.familyTypeId),
        surveyEntityId: parseInt(e.surveyEntityId),
        progress: e.percentage,
        date: e.userStartDate,
        startDate: e.userStartDate,
        dateStatus: e.dateStatus,
        surveyTypeId: e.surveyTypeId,
        status: _.toLower(e.surveyStatus),
        surveyStatus: e.surveyStatus
      };
      if (
        survey.typeId ===
          parseInt(
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_DIRECT")
          ) ||
        survey.typeId ===
          parseInt(
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_WELCOME")
          ) ||
        survey.typeId ===
          parseInt(
            store.getters["Constants/getConstant"](
              "SURVEYTYPE_KYMATIO_GRI_PLUS"
            )
          )
      ) {
        if (
          parseInt(survey.surveyTypeId) ===
            parseInt(
              store.getters["Constants/getConstant"]("SURVEY_KYMATIO_IMPACT")
            ) ||
          parseInt(survey.surveyTypeId) ===
            parseInt(
              store.getters["Constants/getConstant"](
                "SURVEY_KYMATIO_IMPACT_REFRESH"
              )
            )
        ) {
          survey.image = "server.svg";
          survey.color = "tc5";
          survey.showChat = true;
        } else if (
          parseInt(survey.surveyTypeId) ===
            parseInt(
              store.getters["Constants/getConstant"]("SURVEY_KYMATIO_ARCHETYPE")
            ) ||
          parseInt(survey.surveyTypeId) ===
            parseInt(
              store.getters["Constants/getConstant"](
                "SURVEY_KYMATIO_ARCHETYPE_REFRESH"
              )
            ) ||
          parseInt(survey.surveyTypeId) ===
            parseInt(
              store.getters["Constants/getConstant"](
                "SURVEY_KYMATIO_ARCHETYPE_NLP"
              )
            )
        ) {
          survey.image = "fingerprint.svg";
          survey.color = "tc4";
          state.interactions.push(_.clone(survey));
          survey.image = "position.svg";
          survey.color = "tc6";
          survey.showChat = true;
        } else {
          survey.image = "mail-locked.svg";
          survey.color = "tc1";
          survey.showChat = true;
        }
        if (
          survey.typeId ===
          parseInt(
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_WELCOME")
          )
        ) {
          if (_.toLower(survey.surveyStatus) != "finish") {
            survey.link = true;
          } else {
            survey.link = false;
          }
        }
      } else if (
        parseInt(survey.surveyTypeId) ===
        parseInt(
          store.getters["Constants/getConstant"]("SURVEY_KYMATIO_BURNOUT")
        )
      ) {
        survey.image = "fire.svg";
        survey.color = "tc7";
        survey.showChat = false;
      } else if (
        survey.typeId ===
        parseInt(
          store.getters["Constants/getConstant"](
            "SURVEYTYPE_KYMATIO_CLIMA_PLUS"
          )
        )
      ) {
        survey.image = "home-heart.svg";
        survey.color = "tc2";
        survey.showChat = true;
      } else if (
        survey.typeId ===
        parseInt(
          store.getters["Constants/getConstant"](
            "SURVEYTYPE_KYMATIO_CYBERSECURITY"
          )
        )
      ) {
        survey.image = "shield-protected.svg";
        survey.color = "tc3";
        if (survey.status === "pending" || survey.status === "progress") {
          survey.link = true;
          survey.to = {
            name: "Cybertest",
            params: { surveyEntityId: survey.surveyEntityId }
          };
        }
      } else if (
        survey.typeId ===
          parseInt(
            store.getters["Constants/getConstant"](
              "SURVEYTYPE_KYMATIO_OPEN_SURVEY"
            )
          ) ||
        survey.typeId ===
          parseInt(
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_CSAAS")
          )
      ) {
        survey.image = "companies.svg";
        survey.color = "tc8";
        survey.showChat = true;
      }
      state.interactions.push(survey);
    });
  },
  set_done(state) {
    state.status = "success";
  },
  reset(state) {
    state.types = [];
    state.interactions = [];
    state.rawInteractions = [];
    state.status = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
