import axios from "axios";
import {
  getSectionsTranslations,
  getDimensionsTranslations,
  getServicesTranslations
} from "@/api/misc.api";
import { unflatten } from "@/utils/helpers";
import i18n from "@/plugins/i18n";
// import _ from "lodash";

const state = {
  sectionsTranslations: {},
  dimensionsTranslations: {},
  servicesTranslations: {},
  translationsLoaded: ""
};

const getters = {
  sectionsTranslations: state => state.sectionsTranslations,
  servicesTranslations: state => state.servicesTranslations,
  dimensionsTranslations: state => state.dimensionsTranslations,
  areTranslationsLoaded: state => state.translationsLoaded === "success"
};

const mutations = {
  set_servicesTranslations(state, data) {
    state.servicesTranslations = data;
  },
  set_sectionsTranslations(state, data) {
    state.sectionsTranslations = data;
  },
  set_dimensionsTranslations(state, data) {
    state.dimensionsTranslations = data;
  },
  set_translationsLoaded(state) {
    state.translationsLoaded = "success";
  },
  reset(state) {
    state.sectionsTranslations = {};
    state.dimensionsTranslations = {};
    state.servicesTranslations = {};
    state.translationsLoaded = "";
  }
};

const actions = {
  loadTranslations({ commit }) {
    let locale = localStorage.getItem("language");
    axios
      .all([
        getDimensionsTranslations(),
        getSectionsTranslations(),
        getServicesTranslations()
      ])
      .then(
        axios.spread(
          (dimensionsResponse, sectionsResponse, servicesResponse) => {
            let dimensionsTranslations = unflatten(
              dimensionsResponse.data.records
            );
            let sectionsTranslations = unflatten(sectionsResponse.data.records);
            let servicesTranslations = unflatten(servicesResponse.data.records);

            // let entries = Object.entries(servicesTranslations);
            // _.each(entries, section => {
            //   i18n.mergeLocaleMessage(locale, { [section[0]]: section[1] });
            // });

            i18n.mergeLocaleMessage(locale, servicesTranslations.service);
            i18n.mergeLocaleMessage(locale, sectionsTranslations);

            i18n.mergeLocaleMessage(locale, {
              tables: { dimensionIds: dimensionsTranslations }
            });

            commit("set_servicesTranslations", servicesTranslations);
            commit("set_sectionsTranslations", sectionsTranslations);
            commit("set_dimensionsTranslations", dimensionsTranslations);

            commit("set_translationsLoaded");
          }
        )
      );
  },
  reset({ commit }) {
    commit("reset");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
