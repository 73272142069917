/* eslint-disable no-useless-escape */
import _ from "lodash";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
const pwdRegex = /^(?=\P{Ll}*\p{Ll})(?=\P{Lu}*\p{Lu})(?=\P{N}*\p{N})(?=[\p{L}\p{N}]*[^\p{L}\p{N}])[^ñÑ][\s\S]{11,}$/u;
const textRegex = /[a-zA-Z0-9\u00C0-\u017F\s_\.()']+$/;
const digitsRegex = /^[0-9]*$/;
const whitespaceRegex = /\s/g;
const addressRegex = /^[a-zA-Z\d\s/º,.`'çÇñÑ()^¨"´·-]|[à-ü]|[À-Ü]+$/;
const zipCodeRegex = /^[a-zA-Z0-9\d\s/().-]+$/;
const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
const domainRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/m;
const phoneRegex = /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/;
const rfc5322Regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const YYYYMMDDRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
const HHMMSS = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
const sqlRegex = /^(\s*([\0\b\'\"\n\r\t\%\_\\]*\s*(((select\s*.+\s*from\s*.+)|(insert\s*.+\s*into\s*.+)|(update\s*.+\s*set\s*.+)|(delete\s*.+\s*from\s*.+)|(drop\s*.+)|(truncate\s*.+)|(alter\s*.+)|(exec\s*.+)|(\s*(all|any|not|and|between|in|like|or|some|contains|containsall|containskey)\s*.+[\=\>\<=\!\~]+.+)|(let\s+.+[\=]\s*.*)|(begin\s*.*\s*end)|(\s*[\/\*]+\s*.*\s*[\*\/]+)|(\s*(\-\-)\s*.*\s+)|(\s*(contains|containsall|containskey)\s+.*)))(\s*[\;]\s*)*)+)/i;
const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
const htmlTagsRegex = /^[^<>/]*$/;

const validateHTMLTags = html => {
  let re = htmlTagsRegex;
  return re.test(html);
};
const validateHTMLInjection = html => {
  let re = htmlRegex;
  return re.test(html);
};

const validateSQLInjection = sql => {
  let re = sqlRegex;
  return re.test(sql);
};

const validateFormatTimeHHMMSS = time => {
  let re = HHMMSS;
  return _.isEmpty(time) ? false : re.test(time);
};

const validateFormatDateYYYYMMDD = date => {
  let re = YYYYMMDDRegex;
  return _.isEmpty(date) ? false : re.test(date);
};

const validRFC5322 = email => {
  let re = rfc5322Regex;
  return re.test(email);
};

const validUrl = url => {
  var re = urlRegex;
  return re.test(url);
};

const validZipCode = zipCode => {
  var re = zipCodeRegex;
  return re.test(zipCode);
};

const validPhone = phone => {
  var re = phoneRegex;
  return re.test(phone);
};

const validPassword = password => {
  var re = pwdRegex;
  return re.test(password);
};

const validText = text => {
  const re = textRegex;
  return re.test(text);
};

const validDigits = digits => {
  const re = digitsRegex;
  return re.test(Number(digits));
};

const validateWhitespace = text => {
  const re = whitespaceRegex;
  return !re.test(text);
};

const validateAddress = address => {
  const re = addressRegex;
  return re.test(address);
};

const validateDomain = domain => {
  const re = domainRegex;
  return re.test(domain);
};

const validMaxLength = (text, length) => {
  return text.length < length;
};

const validate = (fields, form) => {
  var result = {
    valid: true,
    errors: []
  };

  _.each(fields, group => {
    _.each(group.fields, field => {
      if (field.required && field.type === "datetime") {
        if (
          field.required &&
          (_.isEmpty(form[field.name + "_date"]) ||
            _.isEmpty(form[field.name + "_time"]))
        ) {
          result.valid = false;
          result.errors.push(field);
        }
      } else if (field.required && _.isEmpty(form[field.name])) {
        result.valid = false;
        result.errors.push(field);
      }
      if (field.type === "number") {
        if (field.min && field.min > form[field.name]) {
          result.valid = false;
          result.errors.push(field);
        }
      }
    });
  });

  return result;
};

export {
  validUrl,
  validZipCode,
  validPhone,
  validPassword,
  validText,
  validate,
  validDigits,
  validateWhitespace,
  validateAddress,
  validateDomain,
  validMaxLength,
  validRFC5322,
  validateFormatDateYYYYMMDD,
  validateFormatTimeHHMMSS,
  validateSQLInjection,
  validateHTMLInjection,
  validateHTMLTags,
  urlRegex,
  phoneRegex,
  emailRegex,
  pwdRegex,
  textRegex,
  digitsRegex,
  whitespaceRegex,
  addressRegex,
  domainRegex,
  rfc5322Regex,
  sqlRegex,
  htmlRegex,
  htmlTagsRegex
};
