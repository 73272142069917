import {
  getCampaigns,
  createCampaign,
  getTypes,
  createScheduledCampaign
} from "@/api/controller/campaigns.api";
import { getDepartments } from "@/api/controller/stakeholders.api";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import i18n from "@/plugins/i18n";
import swal from "sweetalert2";
import { getError } from "@/utils/helpers";

const state = {
  status: "",
  error: false,
  campaigns: [],
  departments: [],
  types: [],
  categories: [],
  phishingType: ""
};

const getters = {
  campaigns: state => state.campaigns,
  groupedCampaigns: state => _.groupBy(state.campaigns, "schedule"),
  isCampaignsActive: state => state.status === "success",
  isCampaignCreated: state => state.status === "created",
  types: state => state.types,
  departments: state => state.departments,
  phishingType: state => state.phishingType,
  error: state => state.error
};

const actions = {
  loadPhishingList({ commit, rootGetters }) {
    const stakeholderId = localStorage.getItem("managedCompanyId");

    axios
      .all([
        getCampaigns(stakeholderId, {
          surveyTypes: [
            rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_PHISHING"),
            rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_SMISHING"),
            rootGetters["Constants/getConstant"](
              "SURVEY_KYMATIO_NEUROSMISHING"
            ),
            rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_NEUROPHISHING")
          ]
        })
      ])
      .then(
        axios.spread(campaignsResp => {
          commit("set_campaigns", campaignsResp.data.records);
          commit("set_done", "phishing");
        })
      );
  },
  loadPhishing({ commit }) {
    const stakeholderId = localStorage.getItem("managedCompanyId");
    axios.all([getTypes(stakeholderId), getDepartments(stakeholderId)]).then(
      axios.spread((surveyTypesResp, departmentsResp) => {
        commit("set_types", surveyTypesResp.data.records);
        commit("set_departments", departmentsResp.data.records);
        commit("set_done", "phishing");
      })
    );
  },
  loadNeurophishing({ state, commit, rootGetters }) {
    const stakeholderId = localStorage.getItem("managedCompanyId");
    let calls = [
      getCampaigns(stakeholderId, {
        surveyTypes: [
          rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_NEUROPHISHING")
        ]
      }),
      getDepartments(localStorage.getItem("managedCompanyId"))
    ];
    if (state.types.length > 0) {
      calls.push(getTypes(stakeholderId));
    }
    axios.all(calls).then(
      axios.spread((campaignsResp, departmentsResp, surveyTypesResp) => {
        commit("set_neuro_campaigns", campaignsResp.data.records);
        if (state.types.length > 0) {
          commit("set_types", surveyTypesResp.data.records);
        }
        commit("set_departments", departmentsResp.data.records);
        commit("set_done", "neurophishing");
      })
    );
  },
  insertScheduledCampaign({ commit }, payload) {
    const stakeholderId = localStorage.getItem("managedCompanyId");

    var toSend = {
      name: payload.name,
      startDate: moment
        .tz(
          payload.startDate_date + " " + payload.startDate_time,
          moment.tz.guess()
        )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      surveyTypeId: payload.surveyTypeId,
      totalCampaigns: payload.totalCampaigns,
      frecuency: payload.frecuency,
      campaignKind: payload.campaignKind,
      domain: payload.domain.value,
      landingRedirect: payload.landing.object,
      attachment: payload.attachment.value,
      stakeholders: {
        DEPARTMENTS: _.map(payload.departments, "value")
      }
    };

    if (
      payload.schedule_type == "automatic" &&
      payload.template_type == "neurogaitas"
    ) {
      toSend.levelId = [3];
      toSend.categoryId = [7];
    } else {
      toSend.levelId = _.map(payload.levels, "value");
      toSend.categoryId = _.map(payload.categories, "value");
    }

    createScheduledCampaign(stakeholderId, toSend)
      .then(response => {
        if (response.data.records) {
          commit("reset");
          commit("set_created");
        }
      })
      .catch(error => {
        swal.fire({
          title: i18n.t("errors.title"),
          text: getError(error.response.data.records.applicationCode),
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#ababab"
        });
      });
  },
  insertCampaign({ commit }, payload) {
    const stakeholderId = localStorage.getItem("managedCompanyId");

    let toSend = {
      name: payload.name,
      campaignKind: payload.campaignKind,
      domain: payload.domain.value,
      landingRedirect: payload.landing.object,
      attachment: payload.attachment.value,
      campaignTypeId: payload.campaignTypeId,
      surveyTypeId: payload.surveyTypeId,
      startDate: moment
        .tz(
          payload.startDate_date + " " + payload.startDate_time,
          payload.timezone.value
        )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss")
    };

    if (payload.stakeholderSelection) {
      toSend.stakeholders = {
        DEPARTMENTS: _.map(payload.departments, "value")
      };
    } else {
      let tags = {};
      _.each(_.groupBy(payload.tagValues, "key"), (element, key) => {
        tags[key] = _.map(element, e => e.value);
      });
      toSend.stakeholders = {
        tags: tags
      };
    }

    if (payload.endDate_date) {
      toSend.endDate = moment
        .tz(
          payload.endDate_date + " " + payload.endDate_time,
          payload.timezone.value
        )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }
    if (payload.sendByDate_date) {
      toSend.sendByDate = moment
        .tz(
          payload.sendByDate_date + " " + payload.sendByDate_time,
          payload.timezone.value
        )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    createCampaign(stakeholderId, toSend)
      .then(response => {
        if (response.data.records) {
          commit("reset");
          commit("set_created");
        }
      })
      .catch(error => {
        swal.fire({
          title: i18n.t("errors.title"),
          text: getError(error.response.data.records.applicationCode),
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#ababab"
        });

        if (
          error.response &&
          error.response.data.records &&
          error.response.data.records.applicationCode
        ) {
          commit("set_error", error.response.data.records.applicationCode);
        } else {
          commit("set_error", "generic");
        }
      });
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  set_campaigns(state, campaigns) {
    state.campaigns = campaigns;
  },
  set_neuro_campaigns(state, campaigns) {
    let grouped = _.groupBy(campaigns, "schedule");
    let result = [];
    _.each(grouped, campaign => {
      let mainCampaign = _.first(campaign);
      mainCampaign.children = campaign;
      let minDateObject = _.minBy(campaign, "startDate");
      mainCampaign.minDate = minDateObject && minDateObject.startDate;
      let maxDateObject = _.maxBy(campaign, "endDate");
      mainCampaign.maxDate = maxDateObject && maxDateObject.endDate;
      mainCampaign._showDetails = false;
      result.push(mainCampaign);
    });
    state.campaigns = result;
  },
  set_done(state, type = "phishing") {
    state.error = false;
    state.phishingType = type;
    state.status = "success";
  },
  set_created(state) {
    state.status = "created";
  },
  set_types(state, data) {
    state.types = data;
  },
  set_departments(state, data) {
    state.departments = data;
  },
  set_error(state, data) {
    state.error = data;
  },
  reset(state) {
    state.campaigns = [];
    state.departments = [];
    state.types = [];
    state.status = "";
    state.error = false;
    state.categories = [];
    state.phishingType = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
