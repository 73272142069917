const state = {
  loading: false
};

const getters = {
  isLoading: state => state.loading
};

const actions = {
  show({ commit }) {
    commit("show");
  },
  hide({ commit }) {
    commit("hide");
  }
};

const mutations = {
  show(state) {
    state.loading = true;
  },
  hide(state) {
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
