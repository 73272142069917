import Vue from "vue";

const END_POINT = "/misc";

const getConstants = payload =>
  Vue.axios.get(`${END_POINT}/constants/${payload}`);

const getCountries = () => Vue.axios.get(`${END_POINT}/countries`);

const getSurveyFlows = () => Vue.axios.get(`${END_POINT}/surveyflows`);

const getRegions = () => Vue.axios.get(`${END_POINT}/regions`);

const getRegionsByCountry = countryId =>
  Vue.axios.get(`${END_POINT}/regions/${countryId}`);

const getServicesTranslations = (serviceTypeIds = "ALL") =>
  Vue.axios.get(
    `${END_POINT}/translations/services?serviceTypeIds=${serviceTypeIds}`
  );

const getSectionsTranslations = (sections = "ALL") =>
  Vue.axios.get(`${END_POINT}/translations/global?sections=${sections}`);

const getDimensionsTranslations = (dimensionIds = "ALL") =>
  Vue.axios.get(
    `${END_POINT}/translations/dimensions?dimensionIds=${dimensionIds}`
  );

const getTimezones = () => Vue.axios.get(`${END_POINT}/timezones`);

export {
  getConstants,
  getCountries,
  getRegions,
  getRegionsByCountry,
  getSurveyFlows,
  getDimensionsTranslations,
  getServicesTranslations,
  getSectionsTranslations,
  getTimezones
};
