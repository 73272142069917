import axios from "axios";
import _ from "lodash";

import { extractValueFromProfile } from "@/utils/helpers";
import {
  getDepartments,
  getStakeholder
} from "@/api/controller/stakeholders.api";
import { getProfile, getInteractions } from "@/api/controller/traits.api";

const state = {
  status: "",
  departments: [],
  profiles: {},
  company: {},
  interactions: []
};

const getters = {
  getDepartments: state => {
    return state.departments;
  },
  getProfiles: state => {
    return state.profiles;
  },
  getChartData: state => {
    var groupedByParents = _.groupBy(
      state.departments,
      el => el.stakeholderDepartmentParentId
    );
    var deptsById = _.keyBy(state.departments, el => el.id);
    _.each(_.omit(groupedByParents, null), (children, parentId) => {
      deptsById[parentId].children = children;
      deptsById[parentId].hasChildren = true;
    });
    deptsById = _.filter(deptsById, depart => {
      return depart.stakeholderDepartmentParentId === null;
    });
    var chartData = {
      id: state.company.stakeholderId,
      company: true,
      name: state.company.name,
      relationship: null,
      children: deptsById,
      hasChildren: true
    };

    return chartData;
  },
  getProfileById: state => {
    return (stakeholderId, surveyType, dimensionGroup, dimension) => {
      return extractValueFromProfile(
        state.profiles[stakeholderId],
        dimensionGroup,
        surveyType,
        "dimensionValue",
        state.interactions,
        dimension,
        null,
        false
      );
    };
  },
  orgStatus: state => state.status,
  isOrganizationLoaded: state => state.status === "success"
};

const actions = {
  fetchOrganization: ({ commit, rootGetters }) => {
    const stakeholderId = localStorage.getItem("managedCompanyId");

    axios
      .all([
        getStakeholder("companies", stakeholderId),
        getInteractions(stakeholderId),
        getDepartments(stakeholderId, {
          stakeholderDepartmentParentId: true
        })
      ])
      .then(
        axios.spread(
          (companyResponse, interactionsResponse, departmentsResponse) => {
            commit("set_company", companyResponse.data.records);
            commit("set_interactions", interactionsResponse.data.records);
            commit("set_departments", departmentsResponse.data.records);
            var departsStakeholdersIds = departmentsResponse.data.records.map(
              department => department.stakeholderId
            );
            var params = {
              surveyTypes: [
                rootGetters["Constants/getConstant"](
                  "SURVEY_KYMATIO_GRI_GLOBAL"
                ),
                rootGetters["Constants/getConstant"](
                  "SURVEY_KYMATIO_CLIMATE_GLOBAL"
                ),
                rootGetters["Constants/getConstant"](
                  "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
                ),
                rootGetters["Constants/getConstant"](
                  "SURVEY_KYMATIO_SCORE_GLOBAL"
                )
              ],
              groupDimensions: [
                rootGetters["Constants/getConstant"]("DIMENSION_GROUP_RISK"),
                rootGetters["Constants/getConstant"]("DIMENSION_GROUP_HAZARD"),
                rootGetters["Constants/getConstant"](
                  "DIMENSION_GROUP_CIBERSECURITY"
                ),
                rootGetters["Constants/getConstant"]("DIMENSION_GROUP_WORKING"),
                rootGetters["Constants/getConstant"]("DIMENSION_GROUP_SCORING")
              ],
              dimensions: [
                rootGetters["Constants/getConstant"](
                  "DIMENSION_ELEMENT_CLIMATE_LEVEL"
                ),
                rootGetters["Constants/getConstant"](
                  "DIMENSION_ELEMENT_GENERAL_RISK"
                ),
                rootGetters["Constants/getConstant"](
                  "DIMENSION_ELEMENT_GENERAL_HAZARD"
                ),
                rootGetters["Constants/getConstant"](
                  "DIMENSION_ELEMENT_CIBER_RESULTS"
                ),
                rootGetters["Constants/getConstant"](
                  "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
                )
              ]
            };

            var pager = 0;
            var limit = 50;
            var requests = [];
            while (pager * limit < _.size(departsStakeholdersIds)) {
              requests.push(
                getProfile(
                  stakeholderId,
                  _.merge(
                    {
                      stakeholdersId: _.slice(
                        departsStakeholdersIds,
                        pager * limit,
                        (pager + 1) * limit
                      )
                    },
                    params
                  )
                )
              );
              pager++;
            }
            axios.all(requests).then(
              axios.spread((...responses) => {
                var profiles = false;
                _.each(responses, response => {
                  profiles = _.merge(profiles, response.data.records);
                });
                commit("set_profiles", profiles);
                commit("set_done");
              })
            );
          }
        )
      );
  },
  reset: ({ commit }) => {
    commit("RESET");
  }
};

const mutations = {
  set_company(state, data) {
    state.company = data;
  },
  set_departments(state, data) {
    var departments = [];
    _.each(data, department => {
      var result = {
        id: department.stakeholderId,
        name: department.name,
        stakeholderDepartmentParentId: department.stakeholderDepartmentParentId
      };
      departments.push(result);
    });
    state.departments = departments;
  },
  set_profiles(state, data) {
    state.profiles = data;
  },
  set_interactions(state, data) {
    state.interactions = data;
  },
  set_done(state) {
    state.status = "success";
  },
  SET_ERROR(state) {
    state.status = "error";
  },
  RESET(state) {
    state.status = "";
    state.departments = [];
    state.profiles = {};
    state.company = {};
    state.interactions = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
