import Vue from "vue";
// import router from "@/router";
import * as Sentry from "@sentry/vue";
import * as Integrations from "@sentry/integrations";

if (process.env.VUE_APP_ENVIRONMENT !== "local") {
  Sentry.init({
    Vue,
    dsn: "https://6092b8fcbcc14b8e8939cbb3328bd224@tracking.kymatio.com/3",
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true })
      // Sentry.browserTracingIntegration({ router }),
    ],
    environment: process.env.VUE_APP_ENVIRONMENT,
    ignoreErrors: [
      "Request failed with status code 500",
      "Request failed with status code 502",
      "Request failed with status code 503",
      "Request failed with status code 401",
      "Request failed with status code 403",
      "Request failed with status code 404",
      "Request failed with status code 418"
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}
