import Vue from "vue";

const END_POINT = "/controller/breaches";

const getStakeholderBreaches = (stakeholderId, stakeholderType, params = {}) =>
  Vue.axios.get(`${END_POINT}/${stakeholderType}/${stakeholderId}`, {
    params: params
  });
const getBreach = id => Vue.axios.get(`${END_POINT}/${id}`);
const getBreaches = () => Vue.axios.get(`${END_POINT}`);

export { getStakeholderBreaches, getBreach, getBreaches };
