import Vue from "vue";
import _ from "lodash";

const END_POINT = "/user/traits";

const getUserInteractions = (dummies = true, params = {}) =>
  Vue.axios.get(`${END_POINT}/interactions`, {
    params: _.merge(params, { dummies: dummies })
  });
const getUserInteraction = (
  interactionId,
  chat = false,
  completeQuestions = false
) =>
  Vue.axios.get(
    `${END_POINT}/interactions/${interactionId}?completeQuestions=${completeQuestions}`,
    {
      chat: chat
    }
  );
const getHelpInteraction = (surveyTypeId, chat = false, payload = {}) =>
  Vue.axios.get(`${END_POINT}/helpers/${surveyTypeId}`, {
    params: payload,
    chat: chat
  });

const saveHelpInteraction = (surveyTypeId, payload, chat = false) =>
  Vue.axios.put(`${END_POINT}/helpers/${surveyTypeId}`, payload, {
    chat: chat
  });

const saveInteraction = (
  interactionId,
  payload,
  chat = false,
  completeQuestions = false
) =>
  Vue.axios.put(
    `${END_POINT}/interactions/${interactionId}?completeQuestions=${completeQuestions}`,
    payload,
    {
      chat: chat
    }
  );

//NOTA: params necesita un array de ids
//(surveyTypes, surveyFamilies, dimensions, groupDimensions...)
const getHelpersBots = payload =>
  Vue.axios.get(`${END_POINT}/helpers`, {
    params: { surveyTypes: payload }
  });
const getUserProfile = params =>
  Vue.axios.get(`${END_POINT}/psychoprofile`, {
    params: params
  });
const getUserAlerts = params =>
  Vue.axios.get(`${END_POINT}/alerts`, { params: params });
const getUserActions = params =>
  Vue.axios.get(`${END_POINT}/actions`, { params: params });
const getActionsTexts = params =>
  Vue.axios.get(`${END_POINT}/text-actions`, { params: params });

const getSurveyTypes = (interactionId, params) =>
  Vue.axios.get(`${END_POINT}/common/surveytypes/${interactionId}`, {
    params: params
  });
const getUserAssets = (surveyEntityId = false) => {
  if (surveyEntityId) {
    return Vue.axios.get(`${END_POINT}/assets/${surveyEntityId}`);
  } else {
    return Vue.axios.get(`${END_POINT}/assets`);
  }
};

export {
  getUserInteractions,
  getUserInteraction,
  getHelpInteraction,
  saveHelpInteraction,
  getHelpersBots,
  saveInteraction,
  getUserProfile,
  getUserAlerts,
  getUserActions,
  getActionsTexts,
  getSurveyTypes,
  getUserAssets
};
