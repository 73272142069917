import axios from "axios";
import _ from "lodash";

import { getProfile, getInteractions } from "@/api/controller/traits.api";
import { getDepartments } from "@/api/controller/stakeholders.api";

import { extractValueFromProfile } from "@/utils/helpers";

const state = {
  loading: false,
  interactions: [],
  departments: []
};

const getters = {
  isLoading: state => state.loading,
  departments: state => state.departments
};

const actions = {
  loadDepartments({ commit, rootGetters }) {
    let stakeholderId = localStorage.getItem("managedCompanyId");

    commit("loading");
    axios
      .all([
        getDepartments(stakeholderId, { tags: true }),
        getInteractions(stakeholderId, true)
      ])
      .then(
        axios.spread((departmentsResp, interactionsResp) => {
          commit("set_interactions", interactionsResp.data.records);
          var stakeholdersIds = departmentsResp.data.records.map(
            department => department.stakeholderId
          );
          var psychoParams = {
            surveyTypes: [
              rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL"),
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_CLIMATE_GLOBAL"
              ),
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
              ),
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_SCORE_GLOBAL"
              )
            ],
            groupDimensions: [
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_SCORING"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_INSIDER"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_RISK"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_HAZARD"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_WORKING"),
              rootGetters["Constants/getConstant"](
                "DIMENSION_GROUP_CIBERSECURITY"
              )
            ],
            dimensions: [
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_CLIMATE_LEVEL"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_GENERAL_RISK"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_GENERAL_HAZARD"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_PRINCIPAL_INSIDER"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_CIBER_RESULTS"
              )
            ]
          };

          var pager = 0;
          var limit = 50;
          var requests = [];
          while (pager * limit < _.size(stakeholdersIds)) {
            requests.push(
              getProfile(
                localStorage.getItem("managedCompanyId"),
                _.merge(
                  {
                    stakeholdersId: _.slice(
                      stakeholdersIds,
                      pager * limit,
                      (pager + 1) * limit
                    )
                  },
                  psychoParams
                )
              )
            );
            pager++;
          }
          axios.all(requests).then(
            axios.spread((...responses) => {
              var profiles = false;
              _.each(responses, response => {
                profiles = _.merge(profiles, response.data.records);
              });
              commit("set_departments", {
                profiles: profiles,
                departments: departmentsResp.data.records,
                interactions: interactionsResp.data.records
              });
              commit("loaded");
            })
          );
        })
      );
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  loaded(state) {
    state.loading = false;
  },
  loading(state) {
    state.loading = true;
  },
  set_interactions(state, data) {
    state.interactions = data;
  },
  set_departments(state, data) {
    var departments = data.departments;
    var profiles = data.profiles;

    _.forEach(departments, depart => {
      var stkId = depart.stakeholderId;
      var departmentProfile = profiles[stkId];
      depart.progress = extractValueFromProfile(
        departmentProfile,
        "DIMENSION_GROUP_SCORING",
        "SURVEY_KYMATIO_SCORE_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
        null,
        true
      );
      depart.insider = extractValueFromProfile(
        departmentProfile,
        "DIMENSION_GROUP_INSIDER",
        "SURVEY_KYMATIO_GRI_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_PRINCIPAL_INSIDER",
        null,
        false
      );
      depart.hazard = extractValueFromProfile(
        departmentProfile,
        "DIMENSION_GROUP_HAZARD",
        "SURVEY_KYMATIO_GRI_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_GENERAL_HAZARD",
        null,
        false
      );
      depart.risk = extractValueFromProfile(
        departmentProfile,
        "DIMENSION_GROUP_RISK",
        "SURVEY_KYMATIO_GRI_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_GENERAL_RISK",
        null,
        false
      );
      depart.wellbeing = extractValueFromProfile(
        departmentProfile,
        "DIMENSION_GROUP_WORKING",
        "SURVEY_KYMATIO_CLIMATE_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_CLIMATE_LEVEL",
        null,
        false
      );
      depart.cyber = extractValueFromProfile(
        departmentProfile,
        "DIMENSION_GROUP_CIBERSECURITY",
        "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_CIBER_RESULTS",
        null,
        false
      );
    });
    state.departments = _.orderBy(departments, ["progress"], ["desc"]);
  },
  reset(state) {
    state.loading = false;
    state.departments = [];
    state.interactions = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
