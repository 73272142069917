import axios from "axios";
import _ from "lodash";

import { getProfile, getInteractions } from "@/api/controller/traits.api";
import { getUsers } from "@/api/controller/stakeholders.api";

import { extractValueFromProfile } from "@/utils/helpers";

const state = {
  loading: false,
  interactions: [],
  users: []
};

const getters = {
  isLoading: state => state.loading,
  users: state => _.orderBy(state.users, ["progress"], ["desc"])
};

const actions = {
  loadUsers({ commit, rootGetters }) {
    const stakeholderId = localStorage.getItem("managedCompanyId");

    commit("loading");
    axios
      .all([
        getUsers("companies", stakeholderId, {
          stakeholderDepartmentParentName: true,
          stakeholderDepartmentId: true,
          avatar: true,
          email: true,
          tags: true
        }),
        getInteractions(stakeholderId, true)
      ])
      .then(
        axios.spread((usersResp, interactionsResp) => {
          commit("set_interactions", interactionsResp.data.records);
          var stakeholdersIds = usersResp.data.records.map(
            user => user.stakeholderId
          );
          var psychoParams = {
            surveyTypes: [
              rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL"),
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_CLIMATE_GLOBAL"
              ),
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
              ),
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_SCORE_GLOBAL"
              )
            ],
            groupDimensions: [
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_INSIDER"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_RISK"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_HAZARD"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_WORKING"),
              rootGetters["Constants/getConstant"]("DIMENSION_GROUP_SCORING"),
              rootGetters["Constants/getConstant"](
                "DIMENSION_GROUP_CIBERSECURITY"
              )
            ],
            dimensions: [
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_CLIMATE_LEVEL"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_CONFIDENTIALITY_RISK"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_AVAILABILITY_RISK"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_INTEGRITY_RISK"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_GENERAL_RISK"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_CONFIDENTIALITY_HAZARD"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_AVAILABILITY_HAZARD"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_INTEGRITY_HAZARD"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_PRINCIPAL_INSIDER"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_CIBER_RESULTS"
              )
            ]
          };

          var pager = 0;
          var limit = 50;
          var requests = [];
          while (pager * limit < _.size(stakeholdersIds)) {
            requests.push(
              getProfile(
                stakeholderId,
                _.merge(
                  {
                    stakeholdersId: _.slice(
                      stakeholdersIds,
                      pager * limit,
                      (pager + 1) * limit
                    )
                  },
                  psychoParams
                )
              )
            );
            pager++;
          }
          axios.all(requests).then(
            axios.spread((...responses) => {
              var profiles = false;
              _.each(responses, response => {
                profiles = _.merge(profiles, response.data.records);
              });
              commit("set_users", {
                profiles: profiles,
                users: usersResp.data.records,
                interactions: interactionsResp.data.records
              });
              commit("loaded");
            })
          );
        })
      );
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  loaded(state) {
    state.loading = false;
  },
  loading(state) {
    state.loading = true;
  },
  set_interactions(state, data) {
    state.interactions = data;
  },
  set_users(state, data) {
    var users = data.users;
    var profiles = data.profiles;

    _.forEach(users, user => {
      var stkId = user.stakeholderId;
      var userProfile = profiles[stkId];
      user.progress = extractValueFromProfile(
        userProfile,
        "DIMENSION_GROUP_SCORING",
        "SURVEY_KYMATIO_SCORE_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
        null,
        true
      );
      user.insider = extractValueFromProfile(
        userProfile,
        "DIMENSION_GROUP_INSIDER",
        "SURVEY_KYMATIO_GRI_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_PRINCIPAL_INSIDER",
        null,
        false
      );
      user.risk = extractValueFromProfile(
        userProfile,
        "DIMENSION_GROUP_RISK",
        "SURVEY_KYMATIO_GRI_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_GENERAL_RISK",
        null,
        false
      );
      user.wellbeing = extractValueFromProfile(
        userProfile,
        "DIMENSION_GROUP_WORKING",
        "SURVEY_KYMATIO_CLIMATE_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_CLIMATE_LEVEL",
        null,
        false
      );
      user.cyber = extractValueFromProfile(
        userProfile,
        "DIMENSION_GROUP_CIBERSECURITY",
        "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_CIBER_RESULTS",
        null,
        false
      );
    });
    state.users = users;
  },
  reset(state) {
    state.loading = false;
    state.users = [];
    state.interactions = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
