import store from "@/store";

const getDimensionsConfigBySurveyTypeId = surveyTypeId => {
  switch (surveyTypeId) {
    case parseInt(
      store.getters["Constants/getConstant"]("SURVEY_KYMATIO_CUSTOM_SURVEY001")
    ):
      return {
        group: "DIMENSION_GROUP_CUSTOM",
        value: "dimensionValue",
        element: "DIMENSION_ELEMENT_CUSTOM_RESULT"
      };
    default:
      return false;
  }
};

const getSurveyTypedBySurveyName = name => {
  switch (name) {
    case "SURVEY_KYMATIO_CUSTOM_SURVEY001":
      return parseInt(
        store.getters["Constants/getConstant"](
          "SURVEY_KYMATIO_CUSTOM_SURVEY001"
        )
      );
  }
};

const getBotConfigBySurveyTypeId = surveyTypeId => {
  switch (surveyTypeId) {
    case store.getters["Constants/getConstant"](
      "SURVEY_KYMATIO_CUSTOM_SURVEY001"
    ):
      return {
        bot: {
          name: "CustomBotOneAnswer"
        }
      };
  }
};

export {
  getBotConfigBySurveyTypeId,
  getDimensionsConfigBySurveyTypeId,
  getSurveyTypedBySurveyName
};
