import _ from "lodash";
import axios from "axios";

import { getProfile, getInteractions } from "@/api/controller/traits.api";
import {
  getUsers,
  getDepartments,
  getStakeholder
} from "@/api/controller/stakeholders.api";
import { getStakeholderBreaches } from "@/api/controller/breaches.api";

import { extractValueFromProfile, calcEvolution } from "@/utils/helpers";

const state = {
  interactions: [],
  cybersecurity: {},
  wellbeing: {},
  risk: false,
  score: false,
  progress: 0,
  users: [],
  departments: [],
  dashboardStatus: "",
  data: false,
  awarenessDashboard: false,
  absDashboard: false,
  generalDashboard: false,
  multiriskDashboard: false
};

const getters = {
  interactions: state => state.interactions,
  cybersecurity: state => state.cybersecurity,
  wellbeing: state => state.wellbeing || -1,
  risk: state => (state.risk ? 12 - 2 * state.risk : -1),
  score: state => (state.score ? state.score : -1),
  progress: state => state.progress,
  users: state => state.users,
  usersnum: state => state.users.length,
  departments: state => state.departments,
  isDashboardLoaded: state => state.dashboardStatus !== "",
  awarenessDashboard: state => state.awarenessDashboard,
  absDashboard: state => state.absDashboard,
  generalDashboard: state => state.generalDashboard,
  multiriskDashboard: state => state.multiriskDashboard
};

const actions = {
  loadDashboard({ commit, rootGetters }) {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    var profile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_INSIDER_RED"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_INSIDER_GREEN"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_INSIDER_BLUE"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_ARCHETYPE"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_IMPACT"),
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_INSIDER_BLUE_REFRESH"
        ),
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_ARCHETYPE_REFRESH"
        ),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_IMPACT_REFRESH"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_CLIMATE_GLOBAL"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_SCORE_GLOBAL"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL"),
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
        )
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_WORKING"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_CIBERSECURITY"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_SCORING"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_RISK"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_EXTRAS")
      ],
      dimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_CLIMATE_LEVEL"),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_CIBER_RESULTS"),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_DATAPROTECTION"
        ),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_MALWARE"),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_COMMUNICATIONS"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_PASSWORDMANAGEMENT"
        ),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_WORKPLACE"),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_SOCIALENGINEERING"
        ),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_COMPLIANCE"),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_KYMATIO_SCORE_NEW"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_AGGREGATED_RISK"
        )
      ],
      dateFrom: "1970-01-01",
      dateTo: "now"
    };

    axios
      .all([
        getProfile(stakeholderCompanyId, profile),
        getInteractions(stakeholderCompanyId),
        getUsers("companies", stakeholderCompanyId, {
          stakeholderDepartmentParentName: true,
          stakeholderDepartmentId: true
        }),
        getDepartments(stakeholderCompanyId)
      ])
      .then(
        axios.spread((profileResp, interactionsResp, usersResp, dptResp) => {
          commit("set_interactions", interactionsResp.data.records);
          commit("set_dashboard", profileResp.data.records);
          commit("set_users", usersResp.data.records);
          commit("set_departments", dptResp.data.records);
          commit("set_dashboard_done");
        })
      );
  },
  loadGeneralDashboard({ commit, rootGetters }) {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");

    var globalsProfile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_CLIMATE_GLOBAL"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_SCORE_GLOBAL"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GRI_GLOBAL"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_PHISHING_GLOBAL"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_BREACH_GLOBAL"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_BURNOUT_GLOBAL"),
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
        ),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_ASSETS_GLOBAL")
      ],
      dimensions: [
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_KYMATIO_SCORE_NEW"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
        ),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_CLIMATE_LEVEL"),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_CIBER_RESULTS"),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_PHISHING_SUBMITTED_DATA"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_AGGREGATED_RISK"
        ),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_ASSETS"),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_PHISHING_PUNTUATION"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_BURNOUT_PUNTUATION"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_BREACH_PUNTUATION"
        )
      ],
      dateFrom: "1970-01-01",
      dateTo: "now"
    };

    axios
      .all([
        getProfile(stakeholderCompanyId, globalsProfile),
        getInteractions(stakeholderCompanyId)
      ])
      .then(
        axios.spread((globalsResp, interactionsResp) => {
          commit("set_general_dashboard", {
            score: calcEvolution(
              "DIMENSION_GROUP_SCORING",
              "DIMENSION_ELEMENT_KYMATIO_SCORE_NEW",
              "SURVEY_KYMATIO_SCORE_GLOBAL",
              interactionsResp.data.records,
              globalsResp.data.records
            ),
            punctuation: extractValueFromProfile(
              globalsResp.data.records,
              "DIMENSION_GROUP_SCORING",
              "SURVEY_KYMATIO_SCORE_GLOBAL",
              "dimensionValue",
              interactionsResp.data.records,
              "DIMENSION_ELEMENT_KYMATIO_SCORE_NEW",
              null,
              false
            ),
            phishing: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_PHISHING",
                "SURVEY_KYMATIO_PHISHING_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_PHISHING_PUNTUATION",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_PHISHING",
                "DIMENSION_ELEMENT_PHISHING_PUNTUATION",
                "SURVEY_KYMATIO_PHISHING_GLOBAL",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            abs: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_ABS",
                "SURVEY_KYMATIO_BREACH_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_BREACH_PUNTUATION",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_ABS",
                "DIMENSION_ELEMENT_BREACH_PUNTUATION",
                "SURVEY_KYMATIO_BREACH_GLOBAL",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            burnout: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_BURNOUT",
                "SURVEY_KYMATIO_BURNOUT_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_BURNOUT_PUNTUATION",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_BURNOUT",
                "DIMENSION_ELEMENT_BURNOUT_PUNTUATION",
                "SURVEY_KYMATIO_BURNOUT_GLOBAL",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            participation: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_SCORING",
                "SURVEY_KYMATIO_SCORE_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_SCORING",
                "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
                "SURVEY_KYMATIO_SCORE_GLOBAL",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            cybersecurity: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_CIBERSECURITY",
                "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_CIBER_RESULTS",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_CIBERSECURITY",
                "DIMENSION_ELEMENT_CIBER_RESULTS",
                "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            wellbeing: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_WORKING",
                "SURVEY_KYMATIO_CLIMATE_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_CLIMATE_LEVEL",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_WORKING",
                "DIMENSION_ELEMENT_CLIMATE_LEVEL",
                "SURVEY_KYMATIO_CLIMATE_GLOBAL",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            assets: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_ASSETS",
                "SURVEY_KYMATIO_ASSETS_GLOBAL",
                "dimensionValueExtra",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_ASSETS",
                null,
                false
              ),
              evolution: {}
            }
          });
        })
      );
  },
  loadAwarenessDashboard({ commit, rootGetters }) {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    var profile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
        )
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_CIBERSECURITY")
      ],
      dateFrom: "1970-01-01",
      dateTo: "now"
    };
    axios
      .all([
        getProfile(stakeholderCompanyId, profile),
        getInteractions(stakeholderCompanyId),
        getDepartments(stakeholderCompanyId)
      ])
      .then(
        axios.spread((cyberProfileResp, interactionsResp, departmentsResp) => {
          var stakeholdersIds = departmentsResp.data.records.map(
            department => department.stakeholderId
          );
          var departmentsParams = {
            surveyTypes: [
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
              ),
              rootGetters["Constants/getConstant"](
                "SURVEY_KYMATIO_SCORE_GLOBAL"
              )
            ],
            dimensions: [
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_KYMATIO_CYBER_NOTE"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_DATAPROTECTION"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_COMMUNICATIONS"
              ),
              rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_MALWARE"),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_PASSWORDMANAGEMENT"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_WORKPLACE"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_COMPLIANCE"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_SOCIALENGINEERING"
              ),
              rootGetters["Constants/getConstant"](
                "DIMENSION_ELEMENT_CIBER_RESULTS"
              )
            ]
          };

          var pager = 0;
          var limit = 50;
          var requests = [];
          while (pager * limit < _.size(stakeholdersIds)) {
            requests.push(
              getProfile(
                stakeholderCompanyId,
                _.merge(
                  {
                    stakeholdersId: _.slice(
                      stakeholdersIds,
                      pager * limit,
                      (pager + 1) * limit
                    )
                  },
                  departmentsParams
                )
              )
            );
            pager++;
          }
          axios.all(requests).then(
            axios.spread((...responses) => {
              var profiles = false;
              _.each(responses, response => {
                profiles = _.merge(profiles, response.data.records);
              });
              commit("set_awareness_dashboard", {
                profile: cyberProfileResp.data.records,
                interactions: interactionsResp.data.records,
                departmentsProfiles: profiles,
                departments: departmentsResp.data.records
              });
            })
          );
        })
      );
  },
  loadAbsDashboard({ commit, rootGetters }) {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    var profile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_BREACH_CORPORATE")
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_ABS")
      ],
      dateFrom: "1970-01-01",
      dateTo: "now"
    };
    axios
      .all([
        getProfile(stakeholderCompanyId, profile),
        getInteractions(stakeholderCompanyId),
        getStakeholderBreaches(stakeholderCompanyId, "companies")
      ])
      .then(
        axios.spread((absProfileResp, interactionsResp, breachesResp) => {
          var stakeholders = [];
          var requests = [];
          var lastBreaches = _.take(
            _.orderBy(
              breachesResp.data.records,
              [breach => breach.breach.breachDate],
              ["desc"]
            ),
            Infinity
          );
          var topBreaches = _.take(
            _.orderBy(
              breachesResp.data.records,
              [breach => breach.stakeholders.length],
              ["desc"]
            ),
            Infinity
          );

          _.each(breachesResp.data.records, breach => {
            _.each(breach.stakeholders, stk => {
              stakeholders.push(stk.stakeholderId);
            });
          });
          stakeholders = _.uniq(stakeholders);
          _.each(stakeholders, val =>
            requests.push(getStakeholder("people", val, { avatar: true }))
          );
          axios.all(requests).then(
            axios.spread((...responses) => {
              var profiles = [];
              _.each(responses, response => {
                profiles.push(response.data.records);
              });
              commit("set_abs_dashboard", {
                abs: {
                  value:
                    extractValueFromProfile(
                      absProfileResp.data.records,
                      "DIMENSION_GROUP_ABS",
                      "SURVEY_KYMATIO_BREACH_CORPORATE",
                      "dimensionValueExtra",
                      interactionsResp.data.records,
                      "DIMENSION_ELEMENT_BREACH_TOTAL",
                      null,
                      false
                    ) || 0,
                  evolution: {}
                },
                topBreaches: {
                  value: topBreaches
                },
                lastBreaches: {
                  value: lastBreaches
                },
                stakeholders: profiles
              });
            })
          );
        })
      );
  },
  loadMultiriskDashboard({ commit, rootGetters }) {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");

    var globalsProfile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_MULTI_RISK"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_SCORE_GLOBAL")
      ],
      dimensions: [
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_RISK_CYBER_MEAN"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_RISK_PHISHING_PONDERATED"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_RISK_BURNOUT_MEAN"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_RISK_BREACH_MEAN"
        ),
        rootGetters["Constants/getConstant"](
          "DIMENSION_ELEMENT_RISK_CLIMATE_MEAN"
        ),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_RISK_GRI_MEAN"),
        rootGetters["Constants/getConstant"]("DIMENSION_ELEMENT_RISK_SCORE")
      ],
      dateFrom: "1970-01-01",
      dateTo: "now"
    };

    axios
      .all([
        getProfile(stakeholderCompanyId, globalsProfile),
        getInteractions(stakeholderCompanyId)
      ])
      .then(
        axios.spread((globalsResp, interactionsResp) => {
          commit("set_multirisk_dashboard", {
            awareness: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_MULTI_RISK",
                "SURVEY_KYMATIO_MULTI_RISK",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_RISK_CYBER_MEAN",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_MULTI_RISK",
                "DIMENSION_ELEMENT_RISK_CYBER_MEAN",
                "SURVEY_KYMATIO_MULTI_RISK",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            phishing: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_MULTI_RISK",
                "SURVEY_KYMATIO_MULTI_RISK",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_RISK_PHISHING_PONDERATED",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_MULTI_RISK",
                "DIMENSION_ELEMENT_RISK_PHISHING_PONDERATED",
                "SURVEY_KYMATIO_MULTI_RISK",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            burnout: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_MULTI_RISK",
                "SURVEY_KYMATIO_MULTI_RISK",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_RISK_BURNOUT_MEAN",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_MULTI_RISK",
                "DIMENSION_ELEMENT_RISK_BURNOUT_MEAN",
                "SURVEY_KYMATIO_MULTI_RISK",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            abs: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_MULTI_RISK",
                "SURVEY_KYMATIO_MULTI_RISK",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_RISK_BREACH_MEAN",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_MULTI_RISK",
                "DIMENSION_ELEMENT_RISK_BREACH_MEAN",
                "SURVEY_KYMATIO_MULTI_RISK",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            wellbeing: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_MULTI_RISK",
                "SURVEY_KYMATIO_MULTI_RISK",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_RISK_CLIMATE_MEAN",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_MULTI_RISK",
                "DIMENSION_ELEMENT_RISK_CLIMATE_MEAN",
                "SURVEY_KYMATIO_MULTI_RISK",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            risk: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_MULTI_RISK",
                "SURVEY_KYMATIO_MULTI_RISK",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_RISK_GRI_MEAN",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_MULTI_RISK",
                "DIMENSION_ELEMENT_RISK_GRI_MEAN",
                "SURVEY_KYMATIO_MULTI_RISK",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            score: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_MULTI_RISK",
                "SURVEY_KYMATIO_MULTI_RISK",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_RISK_SCORE",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_MULTI_RISK",
                "DIMENSION_ELEMENT_RISK_SCORE",
                "SURVEY_KYMATIO_MULTI_RISK",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            },
            participation: {
              value: extractValueFromProfile(
                globalsResp.data.records,
                "DIMENSION_GROUP_SCORING",
                "SURVEY_KYMATIO_SCORE_GLOBAL",
                "dimensionValue",
                interactionsResp.data.records,
                "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
                null,
                false
              ),
              evolution: calcEvolution(
                "DIMENSION_GROUP_SCORING",
                "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
                "SURVEY_KYMATIO_SCORE_GLOBAL",
                interactionsResp.data.records,
                globalsResp.data.records
              )
            }
          });
        })
      );
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  set_interactions(state, data) {
    state.interactions = data;
  },
  set_users(state, data) {
    state.users = data;
  },
  set_departments(state, data) {
    state.departments = data;
  },
  set_dashboard(state, data) {
    state.data = data;
    state.score = extractValueFromProfile(
      data,
      "DIMENSION_GROUP_SCORING",
      "SURVEY_KYMATIO_SCORE_GLOBAL",
      "dimensionValue",
      state.interactions,
      "DIMENSION_ELEMENT_KYMATIO_SCORE_NEW",
      null,
      false
    );
    state.risk = extractValueFromProfile(
      data,
      "DIMENSION_GROUP_RISK",
      "SURVEY_KYMATIO_GRI_GLOBAL",
      "dimensionValue",
      state.interactions,
      "DIMENSION_ELEMENT_AGGREGATED_RISK",
      null,
      false
    );
    state.cybersecurity.average = extractValueFromProfile(
      data,
      "DIMENSION_GROUP_CIBERSECURITY",
      "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
      "dimensionValue",
      state.interactions,
      "DIMENSION_ELEMENT_CIBER_RESULTS",
      null,
      false
    );
    state.cybersecurity.data = [
      {
        value: extractValueFromProfile(
          data,
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          state.interactions,
          "DIMENSION_ELEMENT_DATAPROTECTION",
          null,
          false
        ),
        id: "DIMENSION_ELEMENT_DATAPROTECTION",
        name: "dataProtection"
      },
      {
        value: extractValueFromProfile(
          data,
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          state.interactions,
          "DIMENSION_ELEMENT_COMMUNICATIONS",
          null,
          false
        ),
        id: "DIMENSION_ELEMENT_COMMUNICATIONS",
        name: "communications"
      },
      {
        value: extractValueFromProfile(
          data,
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          state.interactions,
          "DIMENSION_ELEMENT_MALWARE",
          null,
          false
        ),
        id: "DIMENSION_ELEMENT_MALWARE",
        name: "malware"
      },
      {
        value: extractValueFromProfile(
          data,
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          state.interactions,
          "DIMENSION_ELEMENT_PASSWORDMANAGEMENT",
          null,
          false
        ),
        id: "DIMENSION_ELEMENT_PASSWORDMANAGEMENT",
        name: "passwordManagement"
      },
      {
        value: extractValueFromProfile(
          data,
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          state.interactions,
          "DIMENSION_ELEMENT_WORKPLACE",
          null,
          false
        ),
        id: "DIMENSION_ELEMENT_WORKPLACE",
        name: "workplace"
      },
      {
        value: extractValueFromProfile(
          data,
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          state.interactions,
          "DIMENSION_ELEMENT_COMPLIANCE",
          null,
          false
        ),
        id: "DIMENSION_ELEMENT_COMPLIANCE",
        name: "compliance"
      },
      {
        value: extractValueFromProfile(
          data,
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          state.interactions,
          "DIMENSION_ELEMENT_SOCIALENGINEERING",
          null,
          false
        ),
        id: "DIMENSION_ELEMENT_SOCIALENGINEERING",
        name: "socialEngineering"
      }
    ];
    state.wellbeing.average = extractValueFromProfile(
      data,
      "DIMENSION_GROUP_WORKING",
      "SURVEY_KYMATIO_CLIMATE_GLOBAL",
      "dimensionValue",
      state.interactions,
      "DIMENSION_ELEMENT_CLIMATE_LEVEL",
      null,
      false
    );
    state.progress = extractValueFromProfile(
      data,
      "DIMENSION_GROUP_SCORING",
      "SURVEY_KYMATIO_SCORE_GLOBAL",
      "dimensionValue",
      state.interactions,
      "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE",
      null,
      false
    );
  },
  set_general_dashboard(state, data) {
    state.generalDashboard = data;
  },
  set_awareness_dashboard(state, data) {
    var dimensions = [
      {
        title: "dataProtection",
        dimension: "DIMENSION_ELEMENT_DATAPROTECTION"
      },
      {
        title: "malware",
        dimension: "DIMENSION_ELEMENT_COMMUNICATIONS"
      },
      {
        title: "communications",
        dimension: "DIMENSION_ELEMENT_MALWARE"
      },
      {
        title: "passwordManagement",
        dimension: "DIMENSION_ELEMENT_PASSWORDMANAGEMENT"
      },
      {
        title: "workplace",
        dimension: "DIMENSION_ELEMENT_WORKPLACE"
      },
      {
        title: "socialEngineering",
        dimension: "DIMENSION_ELEMENT_COMPLIANCE"
      },
      {
        title: "compliance",
        dimension: "DIMENSION_ELEMENT_SOCIALENGINEERING"
      }
    ];

    state.awarenessDashboard = {
      average: extractValueFromProfile(
        data.profile,
        "DIMENSION_GROUP_CIBERSECURITY",
        "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
        "dimensionValue",
        data.interactions,
        "DIMENSION_ELEMENT_CIBER_RESULTS",
        null,
        false
      ),
      data: [
        {
          value: extractValueFromProfile(
            data.profile,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            "DIMENSION_ELEMENT_DATAPROTECTION",
            null,
            false
          ),
          id: "DIMENSION_ELEMENT_DATAPROTECTION",
          name: "dataProtection"
        },
        {
          value: extractValueFromProfile(
            data.profile,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            "DIMENSION_ELEMENT_COMMUNICATIONS",
            null,
            false
          ),
          id: "DIMENSION_ELEMENT_COMMUNICATIONS",
          name: "communications"
        },
        {
          value: extractValueFromProfile(
            data.profile,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            "DIMENSION_ELEMENT_MALWARE",
            null,
            false
          ),
          id: "DIMENSION_ELEMENT_MALWARE",
          name: "malware"
        },
        {
          value: extractValueFromProfile(
            data.profile,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            "DIMENSION_ELEMENT_PASSWORDMANAGEMENT",
            null,
            false
          ),
          id: "DIMENSION_ELEMENT_PASSWORDMANAGEMENT",
          name: "passwordManagement"
        },
        {
          value: extractValueFromProfile(
            data.profile,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            "DIMENSION_ELEMENT_WORKPLACE",
            null,
            false
          ),
          id: "DIMENSION_ELEMENT_WORKPLACE",
          name: "workplace"
        },
        {
          value: extractValueFromProfile(
            data.profile,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            "DIMENSION_ELEMENT_COMPLIANCE",
            null,
            false
          ),
          id: "DIMENSION_ELEMENT_COMPLIANCE",
          name: "compliance"
        },
        {
          value: extractValueFromProfile(
            data.profile,
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            "DIMENSION_ELEMENT_SOCIALENGINEERING",
            null,
            false
          ),
          id: "DIMENSION_ELEMENT_SOCIALENGINEERING",
          name: "socialEngineering"
        }
      ],
      evolution: calcEvolution(
        "DIMENSION_GROUP_CIBERSECURITY",
        "DIMENSION_ELEMENT_CIBER_RESULTS",
        "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
        data.interactions,
        data.profile
      ),
      departments: [],
      alerts: []
    };
    _.each(data.departments, department => {
      var departmentData = {
        stakeholderId: parseInt(department.stakeholderId),
        name: department.name,
        result: extractValueFromProfile(
          data.departmentsProfiles[department.stakeholderId],
          "DIMENSION_GROUP_CIBERSECURITY",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          "dimensionValue",
          data.interactions,
          "DIMENSION_ELEMENT_CIBER_RESULTS",
          null,
          false
        ),
        score: extractValueFromProfile(
          data.departmentsProfiles[department.stakeholderId],
          "DIMENSION_GROUP_SCORING",
          "SURVEY_KYMATIO_SCORE_GLOBAL",
          "dimensionValue",
          data.interactions,
          "DIMENSION_ELEMENT_KYMATIO_CYBER_NOTE",
          null,
          false
        )
      };
      _.each(
        dimensions,
        dimension =>
          (departmentData[dimension.title] = extractValueFromProfile(
            data.departmentsProfiles[department.stakeholderId],
            "DIMENSION_GROUP_CIBERSECURITY",
            "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
            "dimensionValue",
            data.interactions,
            dimension.dimension,
            null,
            false
          ))
      );
      state.awarenessDashboard.departments.push(departmentData);
    });

    if (state.awarenessDashboard.departments.length > 0) {
      var byResult = _.countBy(
        state.awarenessDashboard.departments,
        department =>
          department.result < 5
            ? "danger"
            : department.result < 7
            ? "warning"
            : "success"
      );
      if (byResult.success == state.awarenessDashboard.departments.length) {
        state.awarenessDashboard.alerts.push({
          type: "result",
          level: "success",
          total: 0,
          icon: "double-check.svg",
          to: { name: "Departments" }
        });
      } else {
        byResult.danger > 0 &&
          state.awarenessDashboard.alerts.push({
            type: "result",
            level: "danger",
            total: byResult.danger,
            icon: "shield-disabled.svg",
            to: { name: "Departments" }
          });

        byResult.warning > 0 &&
          state.awarenessDashboard.alerts.push({
            type: "result",
            level: "warning",
            total: byResult.warning,
            icon: "warning-triangle.svg",
            to: { name: "Departments" }
          });
      }

      _.each(dimensions, dimension => {
        var parcialDimension = _.countBy(
          state.awarenessDashboard.departments,
          department =>
            department[dimension.title] < 5
              ? "danger"
              : department[dimension.title] < 7
              ? "warning"
              : "success"
        );
        if (
          parcialDimension.success ==
          state.awarenessDashboard.departments.length
        ) {
          state.awarenessDashboard.alerts.push({
            type: "dimension",
            subtype: dimension.title,
            level: "success",
            total: 0,
            icon: "double-check.svg",
            to: { name: "Departments" }
          });
        } else {
          parcialDimension.danger > 0 &&
            state.awarenessDashboard.alerts.push({
              type: "dimension",
              subtype: dimension.title,
              level: "danger",
              total: parcialDimension.danger,
              icon: "shield-disabled.svg",
              to: { name: "Departments" }
            });

          parcialDimension.warning > 0 &&
            state.awarenessDashboard.alerts.push({
              type: "dimension",
              subtype: dimension.title,
              level: "warning",
              total: parcialDimension.warning,
              icon: "warning-triangle.svg",
              to: { name: "Departments" }
            });
        }
      });
    }
  },
  set_abs_dashboard(state, data) {
    state.absDashboard = data;
  },
  set_multirisk_dashboard(state, data) {
    state.multiriskDashboard = data;
  },
  set_dashboard_done(state) {
    state.dashboardStatus = "ready";
  },
  reset(state) {
    state.interactions = [];
    state.cybersecurity = {};
    state.wellbeing = {};
    state.risk = {
      score: false,
      users: false,
      departments: false
    };
    state.progress = 0;
    state.users = [];
    state.departments = [];
    state.dashboardStatus = "";
    state.awarenessDashboard = false;
    state.absDashboard = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
