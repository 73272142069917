import Vue from "vue";

const END_POINT = "/user/gamings/achievements";

const getStakeholderAchievements = (claimed = false, params = {}) => {
  const result = Vue.axios.get(`${END_POINT}?claimed=${claimed}`, {
    params: params
  });
  return result;
};

const claimAchievement = stakeholderId =>
  Vue.axios.put(`${END_POINT}/${stakeholderId}`);

export { getStakeholderAchievements, claimAchievement };
