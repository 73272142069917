import Vue from "vue";

const END_POINT = "/user/breaches";

const getUserBreaches = (params = {}) =>
  Vue.axios.get(`${END_POINT}`, { params: params });

const getUserBreachesSurvey = (surveyEntityId, params = {}) =>
  Vue.axios.get(`${END_POINT}/${surveyEntityId}`, { params: params });

const getEmailBreaches = (emailAddress, params = {}) =>
  Vue.axios.get(`${END_POINT}/${emailAddress}`, { params: params }); //TODO: Validate email address before send

const updateBreachStatus = (surveyEntityId, breachId) =>
  Vue.axios.put(`${END_POINT}/${surveyEntityId}/${breachId}/true`);

export {
  getUserBreaches,
  getUserBreachesSurvey,
  getEmailBreaches,
  updateBreachStatus
};
