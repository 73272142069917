import Vue from "vue";
import VueI18n from "vue-i18n";

import "moment/locale/es";
import "moment/locale/eu";
import "moment/locale/pl";
import "moment/locale/es-mx";
import "moment/locale/ca";
import "moment/locale/pt";
import "moment/locale/sv";

import _ from "lodash";

Vue.use(VueI18n);

const numberFormats = {
  "en-gb": {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  },
  "es-us": {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  },
  "es-es": {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  },
  cat: {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  },
  "pt-pt": {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  },
  eu: {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  },
  pl: {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  },
  "es-mx": {
    percent: {
      style: "percent"
    },
    maximumFractionDigits: 1
  }
};

const dateTimeFormats = {
  "en-gb": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  },
  "en-us": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  },
  "es-es": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  },
  "pt-pt": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  },
  cat: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  },
  eu: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  },
  pl: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  },
  "es-mx": {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  }
};

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let locale = _.includes(
  _.keys(loadLocaleMessages()),
  localStorage.getItem("language")
)
  ? localStorage.getItem("language")
  : _.includes(_.keys(loadLocaleMessages()), navigator.language.toLowerCase())
  ? navigator.language.toLowerCase()
  : "es-es";

export default new VueI18n({
  dateTimeFormats,
  numberFormats,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "es-es",
  messages: loadLocaleMessages()
});
