import Vue from "vue";

const END_POINT = "/stakeholders";

const getTheme = () => Vue.axios.get(`${END_POINT}/companies/theme`);

const getStakeholderData = (params = {}) =>
  Vue.axios.get(`${END_POINT}/my-profile`, {
    params: params
  });

export { getTheme, getStakeholderData };
